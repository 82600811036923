import axios from 'axios';

export const get = async (path) => {
    return new Promise((resolve, reject) => {
        axios
            .get(path)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
}
