import './AppGrid.css'
import { useState, useEffect } from 'react';
import * as API from '../../api/API';
import Application from '../application/Application';

export default function AppGrid() {
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        getEnvironments();
    }, []);

    const getEnvironments = async () => {
        API.get('/api/environments')
            .then((response) => response.data.map((environment) => getApplications(environment)))
            .catch(error => console.log(error))
    }

    const getApplications = (environment) => {
        const path = '/api/' + environment + '/applications';
        API.get(path)
            .then((response) => response.data.map((appName) => getApplication(environment, appName)))
            .catch(error => console.log(error))
    }

    const getApplication = async (environment, applicationName) => {
        const path = '/api/' + environment + '/application/' + applicationName;
        API.get(path)
            .then((response) => {
                const application = response.data;
                application.environment = environment;
                setApplications(applications => [...applications, application]);
            })
            .catch(error => console.log(error));
    }

    const sortApplications = (applications) => {
        let errors = applications.filter(application => application.status === 0);
        let running = applications.filter(application => application.status === 1);
        let ordered = sortApplicationsByName(errors)
                .concat(sortApplicationsByName(running));
        return ordered;
    }

    const sortApplicationsByName = (applications) => {
        return applications.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            if (nameA === nameB) {
                return sortApplicationsByEnvironment(a, b);
            }
        });
    };

    const sortApplicationsByEnvironment = (a, b) => {
        const environmentA = a.environment;
        const environmentB = b.environment;
        if (environmentA < environmentB) {
            return -1;
        }
        if (environmentA > environmentB) {
            return 1;
        }
        return 0;
    }
    
    return (
        <div className='app-grid'>
            {sortApplications(applications).map((application, i) => (
                <Application application={application} key={i} />
            ))}
        </div>
    );
}